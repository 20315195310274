@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;500;600;700;800;900&display=swap');

html , * {
  font-family: 'Montserrat', sans-serif !important;
}

.font-header{
  font-family: 'Montserrat', sans-serif;
  font-weight: 800
}


.font-homepage{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600
}

.font-color {
    color: #5C6A7A !important;
}

.primary-bg-color {
    background-color: #002248 !important;
}

.secondary-bg-color {
    background-color: #1893F8 !important;
}

.primary-font-color {
    color: #002248 !important;
}

.secondary-font-color {
    color: #1893F8 !important;
}

/* Hamburger Menu */

.hamburger {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    position: relative;
  }
  
  .hamburger-top,
  .hamburger-middle,
  .hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 2px;
    background: #000;
    transform: rotate(0);
    transition: all 0.5s;
  }
  
  .hamburger-middle {
    transform: translateY(7px);
  }
  
  .hamburger-bottom {
    transform: translateY(14px);
  }
  
  .open {
    transform: rotate(90deg);
    transform: translateY(0px);
  }
  
  .open .hamburger-top {
    transform: rotate(45deg) translateY(6px) translate(6px);
  }
  
  .open .hamburger-middle {
    display: none;
  }
  
  .open .hamburger-bottom {
    transform: rotate(-45deg) translateY(6px) translate(-6px);
  }

  .register {
    max-height: calc(100vh - 10px);
    overflow-y: auto;
    scrollbar-width: none;
  }
  .register::-webkit-scrollbar {
    width: 0 !important
    }

    .gm-style {
      font: 600 11px 'Montserrat' !important;
    }