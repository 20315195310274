.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}
.higherIndexNavBar{
  z-index: 10000000032
}
.higherIndexFooter{
  z-index: 999999999999;
}
.higherIndexScroll{
  z-index: 9999999999999;
}
.message {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 20px;
  border-radius: 5px;
  color:white;
}

.user-message {
  align-self: flex-end;
  background-color: #1893f8;
  color:white;

}

.admin-reply {
  align-self: flex-start;
  background-color: #002248;
}


#all-hotels-map{
  z-index: 1;
}
.floating-icon {
  position: fixed;
  top: 50%; /* Adjust the vertical position as needed */
  left: 27%;
  transform: translate(-100%, -50%);
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.floating-icons{
  position: fixed;
  top: 50%; /* Adjust the vertical position as needed */
  left: 2%;
  transform: translate(-100%, -50%);
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

/* .circular-progress {
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.progress-bar {
  width: 50px; /* Adjust the width of the progress bar */
  height: 50px; /* Adjust the height of the progress bar */
}

.progress-bar-circle {
  fill: none;
  stroke: #f2f2f2; /* Adjust the color of the empty portion of the progress bar */
  stroke-width: 16px; /* Adjust the thickness of the progress bar */
}

.progress-bar-fill {
  fill: none;
  stroke: #00a8e0; /* Adjust the color of the filled portion of the progress bar */
  stroke-width: 15px; /* Adjust the thickness of the progress bar */
  stroke-dasharray: 0 100; /* Initial value for the progress bar */
  stroke-dashoffset: 0; /* Initial value for the progress offset */
  transition: stroke-dasharray 0.3s ease-in-out, stroke-dashoffset 0.3s ease-in-out; /* Transition for smooth animation */
}

.progress-number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  font-size: 16px; /* Adjust the font size of the number */
  font-weight: bold;
  color: #000; /* Adjust the color of the number */
}

#popup-root{
  position: relative;
    z-index: 999999999999;
}

.css-1bcmeji {
  z-index:-1;
}

.css-1cjihdz { 
  z-index:-1;
}

.custom-div-class {
  /* Styles for the preceding <div> tag */
  width : 100%;
}

.react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 3;
  }
}

.autosuggestionlist{
  z-index: 1000003; /* Set a high z-index value */
}
.shadowbox {
  box-shadow: 0 4px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 14px 0 rgba(0, 0, 0, 0.19);
}
.autosuggestion {
  width: max-content;
}
.start-learning {
  position: absolute;
  left: 0;
  top: -86px;
  right: 0;
}

.footer-start {
  position: relative;
  width: 80%;
  max-width: 1280px;
  margin: auto;
  background: #1893f8;
  justify-content: space-between;
  align-items: center;
  padding: 53px 73px;
  border-top-right-radius: 126px;
}
.footer {
  display: block;
  width: 100%;
  position: relative;
}
/* @media only screen and (max-width: 990px) and (min-width: 200px) {
  .footer .inner {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  }
  .start-learning {
      position: unset;
  }
} */

@media screen and (max-width: 1024px) {
  .start-learning {
    top: -78px;
  }
}

@media screen and (max-width: 480px) {
  .start-learning {
    padding-left: 5px;
    padding-right: 5px;
  }
  .footer-start {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .footer-start {
    padding: 23px 17px;
  }
}
.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.select-dropdown-arrow {
  appearance: none;
}

.rec.rec-arrow {
  display: none;
}
.pro-pic {
  width: 70px;
  height: 70px;
  border: 1px solid lightgray;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .rec.rec-carousel */
.react-datepicker__header {
  background-color: #fff;
}
.react-datepicker__day--in-selecting-range {
  background: #72c2dfc5;
}
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--selected,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-end {
  background-color: #00aeee !important;
  color: #fff;
}
@media screen and (min-width: 1024px) {
  .react-datepicker__input-container {
    width: 176px;
  }
}
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__day--in-range,
.react-datepicker__year-text--in-range {
  background: #72c2dfc5;
  color: #fff;
}
.react-datepicker {
  font-family: "Montserrat", sans-serif;
  height: 512px !important;
}

.react-datepicker__navigation {
  top: 29px !important;
}

.pac-icon {
  /* background-image: url("https://www.gstatic.com/images/branding/product/1x/maps_24dp.png"); */
  color: #1893f8;
  /* background-size: 12px; */
  /* margin: 5px 6px 6px 0; */
  vertical-align: middle;
}

.pac-item-query {
  font-size: 15px;
}
.pac-item-selected {
  height: 40px;
}
.pac-item-selected,
.pac-item:hover {
  background: #1893f8;
}
.pac-container {
  border-radius: 10px;
  font-family: "Montserrat";
}
.pac-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 17px;
}
.pac-container,
.pac-item {
  width: inherit !important;
}
.pac-target-input {
  padding-left: 2px;
}
.pac-target-input:focus {
  border: none;
  outline: none;
  padding-left: 2px;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

@media screen and (max-width: 768px) {
  .pac-container {
    width: 75% !important;
  }

  .pac-item {
    width: 100% !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 100px) and (max-width: 1028px) {
  .searchbar {
    width: inherit;
  }
}

nav a.active {
  color: #1893f8;
  font-weight: 600;
}

.nav-link {
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0; /* Set initial width to 0% */
  height: 3px;
  border-radius: 50px;
  background-color: #1893f8;
  transition: width 0.3s ease; /* Add a transition for a smooth effect */
}

.nav-link.active::after {
  width: 50%; /* Set width to 50% when active */
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__current-month {
  color: #070127;
  padding: 8px;
  font-size: 16px;
}
.react-datepicker__current-month::before {
  content: attr(data-month);
  color: gray;
  font-size: 80%;
  margin-right: 5px;
}

.react-datepicker__current-month::after {
  content: attr(data-year);
  font-weight: bold;
}

input[type="checkbox"]::before {
  font-size: 12px;
}

.react-datepicker__day-name {
  color: #8f9bb3;
  padding: 3px;
}

.react-datepicker__month,
.react-datepicker__week,
.react-datepicker__day {
  color: #070127 !important;
  padding: 2px;
}
.react-datepicker__day {
  padding-block: 3px;
  padding-left: 8px;
  padding-right: 25px;
  padding-top: 5px;
  font-size: 16px;
}

.break-after-comma {
  display: block;
}

.rec-pagination {
  display: none;
}
.rec-slider-container {
  width: 100%;
}

select option {
  min-height: 30px;
}

.widthcalendar {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .widthcalendar {
    width: 75%;
  }
}
@media screen and (min-width: 1780px) {
  .widthcalendar {
    width: 75%;
  }
}

.react-datepicker__day-name {
  padding-right: 30px;
}

.react-datepicker__day-names {
  font-size: 14px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  /* padding: 0px 100px 100px 100px; */
}
.column {
  flex: 25%;
  max-width: 25%;
}
.img-hover-zoom {
  height: auto;
  position: relative;
  overflow: hidden;
  border: 5px solid #ffff;
}
.img-hover-zoom img {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  border: 0;
  transition: transform 0.5s ease;
}
.img-hover-zoom:hover img {
  transform: scale(1.5);
}

.img-overlay {
  position: absolute;
  top: 8%;
  left: 29%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.wrapperClassName{
  width: 100%;
}
.img-hover-zoom:hover  {
  opacity: 1;
}
.date-picker-container {
  overflow-y: scroll;
  max-height: 200px; /* Set the desired maximum height of the scrolling area */
  touch-action: pan-y;
}

@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 500px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: 0px;
  left: 0px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

@media screen and (max-width: 1280px) {
  .ribbon-top-left span {
    right: -42px;
  }

  .ribbon {
    width: 101px;
    height: 113px;
  }
}

.input-container {
  position: relative;
}
.css-15l6vlx-Et{
  height: 259vh;
  width: 100vw;
}
.fullscreem{
  height:100vh;
}

.zindex {
  z-index: 99999999999;
}

.cross-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 28px;
  color: #070127;
  z-index: 50;
}
.cross-icons {
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 28px;
  color: #070127;
  z-index: 50;
}
.hidescrollbar{
  overflow: hidden;
}
.css-f1j64i {
  top: 43%;
}

.fontforcircle{
  font-size: 9px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.nowhitebackground{mix-blend-mode: multiply;}

.gapforregister{
  margin-bottom: 28px;
}

.popup-zoom {
  animation: zoom-in 0.3s ease-in-out forwards;
  z-index: 999999999999999999;
}

.popupshow {
  animation: zoom-out 0.3s ease-in-out forwards;
}

@keyframes zoom-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.Toastify{
  z-index: 99999999999999999999999999999999999999999999999999999;
  position: relative;
}

.menu-trigger img{
  /* position: absolute; */
  top: 20px;
  right: 20px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
}

.dropdown-menu{
  position: absolute;
  top: 100px;
  right: 20px;
  background-color: #fff;
  border-radius:5%;
  padding: 10px 20px;
  width: 169px;
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -11px;
  right: 14px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
  z-index: -1;
  border-top: 1px solid #e2e8f0; /* Add border to the top */
  border-right: none; /* Add border to the right */
  border-bottom: none; /* Remove border from the bottom */
  border-left: 1px solid #e2e8f0; /* Remove border from the left */
}

.dropdown-menu.active{
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive{
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}



.dropdown-menu ul li{
  padding: 10px  0;
}

.dropdown-menu ul li:hover a{
  cursor: pointer;
}

.dropdown-menu ul li:hover img{
  opacity: 1;
  cursor: pointer;
}

.dropdownItem{
  display: flex;
  margin: 10px auto;
}

.dropdownItem img{
  max-width: 20px;
  /* margin-right: 10px; */
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a{
  max-width: 100px;
  /* margin-left: 10px; */
  transition: var(--speed);
}


.gm-svpc {
  display: none !important;
}

.gm-style-mtc {
  display: none !important;
}

.rounded-border {
  display: inline-block;
  padding: 4px;
  border: 2px solid #4299e1; /* You can change the border color */
  border-radius: 8px; /* Adjust the radius as needed */
}